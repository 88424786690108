import React from "react";
import CombinedLink from "@/components/CombinedLink";
import { motion, useScroll, useTransform } from "framer-motion";

//icon
import { ReactComponent as ErrorIcon } from "@/assets/error/404.svg";
import { ReactComponent as MoonIcon } from "@/assets/error/moon.svg";
import { ReactComponent as PeanutIcon } from "@/assets/error/peanut.svg";
import { ReactComponent as CrownIcon } from "@/assets/error/crown.svg";

export const Error = () => {


  return (
    <div className=" min-h-screen h-full w-screen pt-10  overflow-hidden  absolute top-0 bottom-0 left-0 right-0   ">
      <div className="  md:container w-full relative   md:mx-auto h-full pt-24 px-4">
        <div className="  flex flex-col  w-fit  items-center z-10 h-full  ">
          <div className=" z-10 mb-8  relative  ">
            <div className="  relative right-0 bottom-0 left-0   max-w-[20rem] lg:max-w-[38rem]  w-[80vw]  md:w-[35vw]   ">
              <ErrorIcon className="z-10 text-gray-500 dark:text-dark-content-100" />
            </div>
          </div>
          <div className="mb-8 text-gray-500  text-4xl z-10 dark:text-dark-content-100">
            你個嘢壞咗呀...
          </div>
          <CombinedLink href="/" className="dark:text-dark-content-300 z-10 rounded-3xl bg-primary-500  h-12 flex flex-row items-center justify-center px-14 text-gray-500 text-xl">
              前往首頁
          </CombinedLink>
        </div>
      </div>
      <div className="   z-0  absolute bottom-0   right-0 w-[90vw] sm:w-[70vw]   md:w-[65vw]  lg:max-w-[80rem]">
        <motion.div
          initial={{}}
          animate={{ scaleY: ["100%", "90%", "100%"] }}
          transition={{
            repeat: Infinity,
            ease: "linear",
            repeatDelay: 0,
            duration: 4,
          }}
        >
          <MoonIcon className=" relative right-0   md:top-0 " />
        </motion.div>
        <motion.div
          initial={{}}
          animate={{ top: ["-10%", "-5%", "-10%"] }}
          transition={{
            repeat: Infinity,
            ease: "easeOut",
            repeatDelay: 0,
            duration: 4,
          }}
          className="absolute -top-[10%]  left-[50%]  w-[40%]"
        >
          <PeanutIcon className="  " />
        </motion.div>
        <motion.div
          initial={{}}
          animate={{ top: ["20%", "25%", "20%"] }}
          transition={{
            repeat: Infinity,
            ease: "easeOut",
            repeatDelay: 0,
            duration: 4,
          }}
          className="absolute  top-[25%]  left-[5%] w-[50%]"
        >
          <CrownIcon className="   " />
        </motion.div>
      </div>
    </div>
  );
};
