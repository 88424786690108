import type { GetStaticProps, NextPage } from 'next'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ComponentSharedSeo } from '../lib/remoteGraphQL'
import { enrichPropsWithGlobalInfo, PropsWithGlobalInfo } from '../lib/static-props-enricher/global'
import { MyAppProps } from './_app'
import { makeStyles } from 'tss-react/mui';
import {Error} from "@/components/Error"
const useStyles = makeStyles()(theme => ({
  contentTypography: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  }
}));


const Custom404View: NextPage<Custom404Props & MyAppProps> = (props: Custom404Props & MyAppProps) => {
  const { global } = props;

  const seo = {
    ...global?.defaultSeo,
    metaTitle:"查無條目"
  } as ComponentSharedSeo;

  return (
    <>
      <Seo seo={seo} />
      <Error />
    </>
  )
}

export type Custom404Props = {
} & PropsWithGlobalInfo

export const getStaticProps: GetStaticProps<Custom404Props> = async (context: any) => {

  return {
    props: await enrichPropsWithGlobalInfo({

    } as Custom404Props),
    revalidate: 60,
  }
};

export default Custom404View
